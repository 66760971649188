import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import Spinner from "../components/Spinner"
import InfoSection from "../pages/billing/sections/InfoSection"
import ProcessingSection from "../pages/billing/sections/ProcessingSection"
import ContactPage from "../pages/contact/ContactPage"
import Redirect from "../pages/redirect/Redirect"
import { useAppHandlers } from "../services/actions/app/useAppHandlers"
import { useAuthHandlers } from "../services/actions/auth/useAuthHandlers"
import { useAppStore } from "../stores/application/useAppStore"
import { useAuthStore } from "../stores/auth/useAuthStore"
import { BillingStoreProvider } from "../stores/billing/useBillingStore"
import Invite from "../pages/Invite/Invite"
import OnboardingPage from "../pages/onboarding/OnboardingPage"
import TeamSection from "../pages/onboarding/sections/TeamSection"
import SurveySection from "../pages/onboarding/sections/SurveySection"
import LinkedInSection from "../pages/onboarding/sections/LinkedInSection"
import { AppRoutes } from "../interfaces/store/appStore"
import { useExtensionRequest } from "../services/apis/extension/useExtensionRequest"
import CanvasPage from "../pages/canvas/CanvasPage"
import HomeSection from "../pages/canvas/sections/HomeSection"
import DashboardSection from "../pages/canvas/sections/DashboardSection"
import RouteGuard from "./RouteGuard"
import ConnectionSection from "../pages/canvas/sections/ConnectionSection"
import SettingsSection from "../pages/canvas/sections/SettingsSection"
import DataManagement from "../pages/canvas/components/settings/DataManagement"
import LeadDeltaExtension from "../pages/canvas/components/settings/LeadDeltaExtension"
import TagSection from "../pages/canvas/sections/TagSection"
import TaskSection from "../pages/canvas/sections/TaskSection"
import PlansAndPricingSection from "../pages/billing/sections/PlansAndPricingSection"
import NoteSection from "../pages/canvas/sections/NoteSection"
import BillingPage from "../pages/billing/BillingPage"
import ModifyPlanSection from "../pages/billing/sections/ModifyPlanSection"
import CSVPage from "../pages/csv/CSVPage"
import ImportCSV from "../pages/csv/sections/ImportCSV"
import MappingCSV from "../pages/csv/sections/MappingCSV"
import PaymentSection from "../pages/billing/sections/PaymentSection"
import Checkout from "../pages/billing/sections/Checkout"
import Billing from "../pages/canvas/components/settings/Billing"
import WorkspaceAdmin from "../pages/canvas/components/settings/WorkspaceAdmin"
import TemplatesSection from "../pages/canvas/sections/TemplatesSection"
import InitialLoading from "../components/InitialLoading"
import AuthenticationPage from "../pages/authentication/AuthenticationPage"
import SignUpSection from "../pages/authentication/sections/SignUpSection"
import SignInSection from "../pages/authentication/sections/SignInSection"
import ForgotPasswordSection from "../pages/authentication/sections/ForgotPasswordSection"
import DisabledSection from "../pages/canvas/sections/DisabledSection"
import { IntervalProvider } from "./IntervalContext"
import UnderConstruction from "../pages/UnderConstruction"


interface IAppRouterProps { }

const AppRouter: FunctionComponent<IAppRouterProps> = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const underConstruction = false

    const { store: { user, workspace, disablePremiumFeatures } } = useAuthStore()

    const { setAuthDataHandler } = useAuthHandlers()
    const { syncExtensionId } = useAppHandlers()

    const [userLoading, setUserLoading] = useState<boolean>(true)
    const [onboardingRoute, setOnboardingRoute] = useState<string>('')
    const { store: { requestLoading, onboardingStep } } = useAppStore()
    const { setPaymentConfirmModal } = useAppHandlers()
    const { getSessionTokens } = useExtensionRequest()
    const navigate = useNavigate()

    const init = React.useCallback(async () => { // we are leaving this because of the inbox We should introduce the check here for the inbox if the extension exists
        // Get extension tokens
        const tokens: any = await getSessionTokens()

        const {
            accessToken,
            refreshToken
        } = tokens || {};

        // update
        setAuthDataHandler({
            accessToken,
            refreshToken
        }, accessToken !== undefined);
    }, []);

    React.useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const promoCouponCode = searchParams.get('promoCouponCode')

        if (localStorage.getItem('promoCouponCode')) {
            localStorage.removeItem('promoCouponCode')
        }
        if (typeof promoCouponCode === 'string' && promoCouponCode?.length !== 0) {
            localStorage.setItem('promoCouponCode', JSON.stringify(promoCouponCode))
        }
    }, [])

    useEffect(() => {
        const appID = searchParams.get('appId') ?? undefined;

        syncExtensionId(appID);

        const successModal = searchParams.get('successModal');

        if (successModal) setPaymentConfirmModal();

        if (appID || successModal) setSearchParams(undefined)
    }, [searchParams])


    useEffect(() => {
        if('user' in requestLoading) setUserLoading(requestLoading?.['user'])
    }, [requestLoading]);

    useEffect(() => {
        let onboardingRoute = '';
        switch (onboardingStep) {
            case 0:
                onboardingRoute = AppRoutes.ONBOARDING
                break;
            case -1:
                onboardingRoute = AppRoutes.HOME
                break;
            case 2:
                onboardingRoute = AppRoutes.ONBOARDING_LINKEDIN
                break;
            case 1:
                onboardingRoute = AppRoutes.ONBOARDING_SURVEY
                break;      
            default:
                onboardingRoute = ''
                break;
        }
        setOnboardingRoute(onboardingRoute);
        if(onboardingRoute.length) navigate(AppRoutes.PROCESSING)
    }, [onboardingStep])

    //todo: later use spinner when navigating
    return (<>{underConstruction ? <UnderConstruction /> : userLoading ? <InitialLoading showInitialLoading={true} /> :
        <Routes>
            {user ? <>
                <Route path={AppRoutes.ONBOARDING} element={<OnboardingPage />}>
                        {!workspace && <Route path="" element={<TeamSection />} />}
                        <Route path={AppRoutes.ONBOARDING_SURVEY}  element={<SurveySection />} />
                        <Route path={AppRoutes.ONBOARDING_LINKEDIN}  element={<LinkedInSection />} />
                </Route>
                {!!onboardingRoute.length && <Route path={AppRoutes.PROCESSING} element={<Navigate to={onboardingRoute} replace />} />}
                <Route element={<IntervalProvider><RouteGuard /></IntervalProvider>}>
                    <Route element={<CanvasPage />}>
                        {disablePremiumFeatures && <Route path={AppRoutes.DISABLED} element={<DisabledSection />} />}
                        {!disablePremiumFeatures && <>
                        <Route path={AppRoutes.HOME} element={<HomeSection />} />
                        <Route path={AppRoutes.DASHBOARD} element={<DashboardSection />} />
                        <Route path={AppRoutes.CONNECTIONS} element={<ConnectionSection />} />
                        <Route path={AppRoutes.TAGS} element={<TagSection />} />
                        <Route path={AppRoutes.TASKS} element={<TaskSection />} />
                        <Route path={AppRoutes.TEMPLATES} element={<TemplatesSection />} />
                        <Route path={AppRoutes.NOTES} element={<NoteSection />} />
                        </>}
                        <Route path={AppRoutes.SETTINGS} element={<SettingsSection />}>
                            {!disablePremiumFeatures && <>
                            <Route path={AppRoutes.SETTINGS_DATA_MANAGEMENT} element={<DataManagement />} />
                            <Route path={AppRoutes.SETTINGS_EXTENSION} element={<LeadDeltaExtension />} />
                            </>}
                            <Route path={AppRoutes.SETTINGS_BILLING} element={<Billing />} />
                            <Route path={AppRoutes.SETTINGS_WORKSPACE_ADMIN} element={<WorkspaceAdmin />} />
                        </Route>
                    </Route>
                    {!disablePremiumFeatures && <Route element={<CSVPage />}>
                        <Route path={AppRoutes.CSV_IMPORT} element={<ImportCSV />} />
                        <Route path={AppRoutes.CSV_MAPPING} element={<MappingCSV />} />
                    </Route>}
                    <Route path={AppRoutes.BILLING} element={<BillingStoreProvider><BillingPage /></BillingStoreProvider>}>
                        <Route path="" element={<PlansAndPricingSection />} />
                        <Route path={AppRoutes.BILLING_PLAN} element={<ModifyPlanSection />} />
                        <Route element={<Checkout />}>
                            <Route path={AppRoutes.BILLING_PAYMENT} element={<PaymentSection />} />
                            <Route path={AppRoutes.BILLING_PROCESSING} element={<ProcessingSection />} />
                        </Route>
                    </Route>
                </Route>
            </> : <Route element={<AuthenticationPage />}>
                <Route path={AppRoutes.SIGNUP}  element={<SignUpSection />} />
                <Route path={AppRoutes.SIGNIN}  element={<SignInSection />} />
                <Route path={AppRoutes.FORGOT_PASSWORD} element={<ForgotPasswordSection />} />
            </Route>}

            <Route path={AppRoutes.INFO} element={<InfoSection />} />
            <Route path={AppRoutes.REDIRECT} element={<Redirect />} />
            <Route path={AppRoutes.INVITE} element={<Invite />} />

            <Route
                path="*"
                element={<Navigate to={disablePremiumFeatures && user ? AppRoutes.DISABLED : user ? AppRoutes.HOME : AppRoutes.SIGNUP} />}
            />
        </Routes>}</>)
}

export default AppRouter