import { IAuthData } from "../../../interfaces/store/authStore";
import { useChromeRuntime } from "../useChromeRuntime"

export const useExtensionRequest = () => {
    const { sendMessage } = useChromeRuntime();
    
    // Extension status - GET
    const getExtensionStatus = () => {
        return sendMessage({
            slug: 'extensionStatus',
            action: 'action',
            url: 'status'
        })
    }

    //User related calls - GET
    const getLoggedInLIUser = () => {
        return sendMessage({
            slug: 'linkedInUser',
            action: 'linkedinAction',
            url: 'getLoggedInUser'
        })
    }

    //Get tokens from extenstion - GET
    const getSessionTokens = () => {
        return sendMessage({
            slug: 'getSessionTokens',
            action: 'action',
            url: 'getAppTokens'
        });
    }

    //all set to true since error handler is outside
    const getProfileContactInfo = (payload: { profileId: string }) => {
        return sendMessage({
            slug: 'getProfileContactInfo',
            action: 'linkedinAction',
            url: 'getProfileContactInfo',
            payload,
            all: true
        });
    }

    const getProfileView = (payload: { profileId: string }) => {
        return sendMessage({
            slug: 'getProfileView',
            action: 'linkedinAction',
            url: 'getProfileView',
            payload,
            all: true
        });
    }

    //This profileId is public identifier, not a real profile id
    const getProfileExtraData = (payload: { profileId: string }) => {
        return sendMessage({
            slug: 'getExtraProfileData',
            action: 'linkedinAction',
            url: 'getExtraProfileData',
            payload,
            all: true
        });
    }

    const syncConnections = (forceUpdate?: boolean) => {
        return sendMessage({
            slug: 'syncConnections',
            action: 'linkedinAction',
            url: 'getFormattedOwnContacts',
            payload: forceUpdate ? { forceUpdate } : undefined
        })
    }

    const syncConversations = () => {
        return sendMessage({
            slug: 'inboxGetConversations',
            action: 'action',
            url: 'inboxGetConversations',
            payload: {
                params: {
                    pinned: false,
                    salesNavigator: false,
                    date: undefined,
                    filter: { archived: 'All', read: 'All' },
                    query: ''
                }
            }
        })
    }

    //Push tokens to extenstion - PUT
    const putSessionTokens = (payload: IAuthData) => {
        return sendMessage({
            slug: 'putSessionTokens',
            action: 'action',
            url: 'putAppTokens',
            payload
        })
    }

    const putWorkspace = (payload: any) => {
        return sendMessage({
            slug: 'putWorkspace',
            action: 'action',
            url: 'putWorkspace',
            payload
        })
    }

    //Cheap trick so the store is initialized on the onboarding
    const getLDAccessToken = () => {
        return sendMessage({
            slug: 'getLDAccessToken',
            action: 'action',
            url: 'getLDAccessToken'
        })
    }

    const messageProfile = (profileId: string, messageText: string) => {
        return sendMessage({
            slug: 'messageProfile',
            action: 'linkedinAction',
            url: 'messageProfile',
            payload: {
                profileId,
                messageText
            }
        })
    }

    const followProfile = (profileId: string) => {
        return sendMessage({
            slug: 'follow',
            action: 'linkedinAction',
            url: 'follow',
            payload: {
                profileId
            }
        })
    }

    const unfollowProfile = (profileId: string) => {
        return sendMessage({
            slug: 'unfollow',
            action: 'linkedinAction',
            url: 'unfollow',
            payload: {
                profileId
            }
        })
    }

    const disconnectProfile = (profileId: string) => {
        return sendMessage({
            slug: 'disconnect',
            action: 'linkedinAction',
            url: 'disconnect',
            payload: {
                profileId
            }
        })
    }

    return {
        getExtensionStatus,
        getLoggedInLIUser,
        putSessionTokens,
        putWorkspace,
        getSessionTokens,
        syncConnections,
        syncConversations,
        getProfileContactInfo,
        getProfileView,
        getProfileExtraData,
        messageProfile,
        followProfile,
        unfollowProfile,
        disconnectProfile,
        getLDAccessToken
    }

}