import { FunctionComponent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Column from "../../components/Column";
import FormControl from "../../components/FormControl";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Row from "../../components/Row";
import CustomTextArea from "../../components/CustomTextAreaV2";
import ActionText from "../../components/ActionText";
import { useTemplateHandlers } from "../../services/actions/template/useTemplateHandlers";
import { SidebarTypes } from "../../interfaces/store/appStore";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import Select from "../../components/select/Select";
import Text from "../../components/Text";

interface ICreateEditTemplateFormProps {
    template?: any
    formType: string,
    duplicate?: boolean,
    onMessageSaveHandler?: (e: string) => {} //this should be reused no matter the
}

interface InputEvent {
    target: {
        value: string
    }
}

interface TextareaEvent {
    target: any;
}

const CreateEditTemplateForm: FunctionComponent<ICreateEditTemplateFormProps> = ({template, formType, duplicate = false, onMessageSaveHandler}) => {
    const textareaRef = useRef(null)

    const isMessage = formType === 'message' ? true : false
    const isTemplate = formType === 'template' ? true : false

    const { createTemplateHandler, updateTemplateHandler, getTemplatesSelectHandler } = useTemplateHandlers()
    const { closeSidebar } = useAppHandlers()

    const [name, setName] = useState<string>('')
    const [content, setContent] = useState('')
    const [cursorPosition, setCursorPosition] = useState(0)

    const [templates, setTemplates] = useState([])

    useEffect(() => {
        if(isMessage) getTemplates()
    }, [])

    const getTemplates = async () => {
        const { templates } = await getTemplatesSelectHandler()
        const optionTemplates = templates.map((template: any) => {
            return {
                label: template.name,
                value: template.content
            }
        })
        console.log(optionTemplates)
        setTemplates(optionTemplates)
    }

    const changeContentHandler = ({ target: { value } }: InputEvent) => setContent(value)
    const onTextareaBlur = ({ target: { selectionStart } }: TextareaEvent) => {
        if (selectionStart !== null) {
          setCursorPosition(selectionStart);
        }
    }

    const addFirstNameHandler = () => {
        if (content.trim().length + 14 > 2000) return

        if (content.length === cursorPosition) {
            if (content) {
                setContent(`${content.trim()} {{firstName}}`)
                setCursorPosition(content.trim().length + 14)
            } else {
                setContent('{{firstName}}')
                setCursorPosition(13)
            }
        } else {
            const stringBefore = content.substring(0, cursorPosition).trim()
            const stringAfter = content.substring(cursorPosition, content.length).trim()
            setContent(`${stringBefore} {{firstName}} ${stringAfter}`)
            setCursorPosition(stringBefore.length + 14)
        }
    }
    const addLastNameHandler = () => {
        if (content.trim().length + 13 > 2000) return

        if (content.length === cursorPosition) {
            if (content) {
                setContent(`${content.trim()} {{lastName}}`)
                setCursorPosition(content.trim().length + 13)
            } else {
                setContent('{{lastName}}')
                setCursorPosition(12)
            }
        } else {
            const stringBefore = content.substring(0, cursorPosition).trim()
            const stringAfter = content.substring(cursorPosition, content.length).trim()
            setContent(`${stringBefore} {{lastName}} ${stringAfter}`)
            setCursorPosition(stringBefore.length + 13)
        }
    }
    const addCompanyHandler = () => {
        if (content.trim().length + 12 > 2000) return

        if (content.length === cursorPosition) {
            if (content) {
                setContent(`${content.trim()} {{company}}`)
                setCursorPosition(content.trim().length + 12)
            } else {
                setContent('{{company}}')
                setCursorPosition(11)
            }
        } else {
            const stringBefore = content.substring(0, cursorPosition).trim()
            const stringAfter = content.substring(cursorPosition, content.length).trim()
            setContent(`${stringBefore} {{company}} ${stringAfter}`)
            setCursorPosition(stringBefore.length + 12)
        }
    }
    const onSaveHandler = () => {

        if(isTemplate) {
            if(!template || (template && duplicate)) {
                console.log(!template ? 'Create New Template' : (template && duplicate) ? 'Duplicate template' : null)
                createTemplateHandler({
                    name: name.trim(),
                    content: content.trim()
                })
            } else {
                console.log('Update template')
                updateTemplateHandler({
                    _id: template._id,
                    name: name.trim(),
                    content: content.trim()
                })
            }
        } else if(isMessage) {
            onMessageSaveHandler && onMessageSaveHandler(content.trim())
        }
        
        if(isTemplate) {
            closeSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE)
        } else if(isMessage) {
            closeSidebar(SidebarTypes.BULK_MESSAGE)
        } 
    }


    useEffect(() => {
        if(template) {
            setName(duplicate ? `Copy ${template.name}` : template.name)
            setContent(template.content)
        }
    }, [])

    return (
        <Column gap="20px">
            {isTemplate && 
                <FormControl $orientation="column" label="Name">
                    <Input maxLength={100} prefilledValue={name} placeholder="Enter template name" onChangeHandler={(e: any) => setName(e)} />
                </FormControl>}
            {isMessage && 
                <FormControl $orientation="column">
                    <Select 
                        placeholder="Choose message template..."
                        isSearchable={false}
                        options={templates}
                        onChange={(e: any) => setContent(e.value)}
                    />
                </FormControl>}
            <Row spaceBetween>
                <ActionText onClickHandler={addFirstNameHandler}>{'+ FirstName'}</ActionText>
                <ActionText onClickHandler={addLastNameHandler}>{'+ LastName'}</ActionText>
                <ActionText onClickHandler={addCompanyHandler}>{'+ Company'}</ActionText>
            </Row>
            <CustomTextArea
                ref={textareaRef}
                maxLength={2000} 
                style={{ height: '250px' }} 
                placeholder='Enter a template message here...'
                onChange={changeContentHandler}
                onBlur={onTextareaBlur}
                value={content}
            />
            {isMessage && <Text $red>Important notice: The message will only be sent to your 1st-degree connections.</Text>}
            <ButtonWrapper>
                <Button $type="cancel" onClickHandler={() => isTemplate ? closeSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE) : closeSidebar(SidebarTypes.BULK_MESSAGE)}>Cancel</Button>
                <Button $type="blue"  onClickHandler={onSaveHandler}>{isTemplate ? 'Save' : 'Send'}</Button>
            </ButtonWrapper>
        </Column>
    )
}
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 10px;
`
export default CreateEditTemplateForm