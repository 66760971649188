import { FunctionComponent, useEffect, useRef, useState } from "react";
import Table from "../../../../components/Table";
import { calculateColumnWidth } from "../../../../utils";
import moment from "moment";
import { SidebarTypes } from "../../../../interfaces/store/appStore";
import { useAppHandlers } from "../../../../services/actions/app/useAppHandlers";
import EditDeleteActionDropdown from "../../../../fragments/dropdowns/EditDeleteActionDropdown";
import Column from "../../../../components/Column";
import Text from "../../../../components/Text";
import { useTemplateHandlers } from "../../../../services/actions/template/useTemplateHandlers";
import { useTemplateStore } from "../../../../stores/templates/useTemplateStore";
import { useAppStore } from "../../../../stores/application/useAppStore";
import SvgFragment from "../../../../fragments/SvgFragment";
import EmptyPage from "../../../../components/EmptyPage";
import useMediaQuery from "../../../../components/useMediaQuery";
import ActionText from "../../../../components/ActionText";

interface ITemplatesTableProps {
    templates?: any
}

const TemplatesTable: FunctionComponent<ITemplatesTableProps> = ({templates}) => {
    const tableRef = useRef<HTMLDivElement>(null)
    const isSmall = useMediaQuery("(max-width: 768px)")

    const { openSidebar } = useAppHandlers()
    const { deleteTemplateHandler } = useTemplateHandlers()
    const { store: { templatesParameters } } = useTemplateStore()
    const { store: { requestLoading }} = useAppStore()
    const [ templatesLoading, setTemplatesLoading ] = useState<boolean>(true)
    const { pageSize } = templatesParameters

    const noResultTitle = "No Templates to Show";
    const noResultDescription = "Create, edit, reuse templates.";

    const [columns, setColumns] = useState([
        { header: 'Template name', key: ['templateNameColumn'], show: true, width: '450px', showSmall: true },
        { header: 'Type', key: ['type'], show: true },
        { header: 'Date created', key: ['dateCreatedColumn'], show: true },
        { header: 'Author', key: ['authorColumn'], show: true },
        { header: '', key: ['editTemplate'], show: true, width: '100px' },
        { header: 'Actions', key: ['actions'], show: true, width: '100px', showSmall: true }
    ])

    useEffect(() => {
        if('paginateTemplates' in requestLoading) setTemplatesLoading(requestLoading?.['paginateTemplates'])
    }, [requestLoading]);

    useEffect(() => {
        
        const offsetWidth = tableRef.current?.offsetWidth

        const adjustedColumns = calculateColumnWidth(offsetWidth, columns, isSmall)
        setColumns(adjustedColumns)

    }, [templates, isSmall])

    return (
        <Table
            ref={tableRef}
            columns={columns}
            noDataPage={<EmptyPage svgType='templateEmpty' title={noResultTitle} label={noResultDescription} />}
            pageLoading={!templates?.length && templatesLoading ? pageSize : undefined}
            data={templates?.map((template: any) => {
                return ({
                    ...template?.author !== 'Default' && { onRowClick: () => openSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE, { formType: 'template', template: template }) },
                    templateNameColumn: (
                        <Column>
                            <Text $label $ellipsis="0">{template.name}</Text>
                            <Text $ellipsis="3">{template.content}</Text>
                        </Column>
                    ),
                    dateCreatedColumn: moment(template.createdAt).format('MMM D, YYYY [at] HH:mm'),
                    authorColumn: <Text $ellipsis="0">{template?.author}</Text>,
                    actions: (
                        <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>
                            <EditDeleteActionDropdown
                                title="Duplicate Template"
                                object={template}
                                type='template'
                                deleteEnabled={template?.author !== 'Default'}
                                onDeleteHandler={() => deleteTemplateHandler(template._id)}
                                onEditHandler={() => {openSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE, { formType: 'template', template: template, duplicate: true})}}
                            />
                        </div>
                    ),
                    ...template
                })
            })}

        />
    )
}

export default TemplatesTable