import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";
//import TabsNameInput from '../Inputs/TabsNameInput'
//import LDTooltip from 'components/LDTooltip/LDTooltip';
import Input from "./Input";
import TabsView, { ITabProperties } from "./TabsView";
import Text from "./Text";
import { styles } from "../styles/themes/style";
import Select from '../components/select/Select'

interface ITabsSearchSelectProps {
  tabs: ITabProperties[];
  infoTitle: string;
  searchByNameValue?: string;
  selectedItems?: any;
  children: ReactNode;
  overflow?: string;
  resetDisabled?: boolean;
  inputSelect?: boolean;
  searchByNameHandler?: (e: any) => void;
  setSelectedTab: (e: any) => void;
  onSelectedItemHandler?: (e?: any, item?: any) => void;
  resetSelectedValues: (e: any) => void;
}


interface IListChildrenStyledProps {
  overflow?: string;
}

const TabsSearch: FunctionComponent<ITabsSearchSelectProps> = ({
  tabs,
  infoTitle,
  searchByNameValue,
  selectedItems,
  searchByNameHandler,
  setSelectedTab, 
  onSelectedItemHandler,
  resetDisabled,
  inputSelect,
  resetSelectedValues,
  children,
  overflow = "true"
}) => {
  const theme: DefaultTheme = useTheme()
  const [inputValue, setInputValue] = useState<any>('')

  useEffect(() => {
    if(inputSelect) setInputValue('')
  }, [selectedItems])

  const handleMultiValueRemove = (event: any, props: any) => {
    event.preventDefault()
    onSelectedItemHandler && onSelectedItemHandler([props?.data])
  }

  const onInputChangeHandler = (event: any, actions: any) => {
    if(actions?.action === 'input-change') {
      setInputValue(event)
      searchByNameHandler && searchByNameHandler(event)
    }
  }

  return (
    <>
    {searchByNameHandler && (
      inputSelect ? (
        <Select 
          menuIsOpen={false}
          isClearable={false}
          isMultiRemove
          placeholder="Search by name..."
          value={selectedItems.map((selectedItem: any) => { return {label: selectedItem?.title, ...selectedItem} })}
          inputValue={inputValue}
          onInputChange={onInputChangeHandler}
          handleMultiValueRemove={handleMultiValueRemove}
        />
      ) : (
        <Input
          placeholder="Search by name"
          onChangeHandler={(e: any) => searchByNameHandler(e)}
        />
      )
    )}
      <TabsView infoTitle={infoTitle} tabs={tabs} onSwitchTab={(e) => setSelectedTab(e)} onReset={(e: any) => resetSelectedValues(e)}></TabsView>
      <ListChildren overflow={overflow}>{children}</ListChildren>
    </>
  );
};
const ListChildren = styled.div<IListChildrenStyledProps>`
  max-height: 224px;
  ${(props) => props.overflow && "overflow: auto"};
`;

export default TabsSearch;
