import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { styles } from "../../styles/themes/style";

import Text from "../../components/Text";
import Input from "../../components/Input";
import Tag from "../../components/Tag";
import Button from "../../components/Button";
import Column from "../../components/Column";
import DueDate from "../DueDate";
import Reminder from "../Reminder";
import Select from "../../components/select/Select";
import CustomTextArea from "../../components/CustomTextAreaV2";
import { ITask } from "../../interfaces/models/task";
import ConnectionAutocompleteSelect from "../ConnectionAutocompleteSelect";
import { useTasksHandlers } from "../../services/actions/tasks/useTasksHandlers";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import { SidebarTypes } from "../../interfaces/store/appStore";
import { IConnection } from "../../interfaces/models/connection";
import { useTasksStore } from "../../stores/tasks/useTasksStore";
import { useRemindersHandlers } from "../../services/actions/reminders/useRemindersHandlers";
import { useWorkspaceHandlers } from "../../services/actions/workspace/useWorkspaceHandlers";
import { capitalLetters } from "../../utils";
import Row from "../../components/Row";
import ProfilePhoto from "../../components/ProfilePhoto";

interface ITasksSidebarProps {
    task?: any
    formType?: string
    connection?: IConnection
    onCloseHandler?: () => void
    onCreateHandler?: (taskId: string | null, task: any) => void
}

const CreateEditTaskForm: FunctionComponent<ITasksSidebarProps> = ({ task, formType = 'all', connection, onCloseHandler, onCreateHandler}) => {
    const editTask = task !== undefined

    const isAll = formType === 'all' ? true : false
    const isReminder = formType === 'reminder' ? true : false
    const isDueDate = formType === 'due date' ? true : false
    const isPriorityLevel = formType === 'priority level' ? true : false
    const isShortForm = formType === 'short' ? true : false

    const priorityOptions = [
        { value: 0, label: 'No Priority Level', component: <Text $lighter>No Priority Level</Text> },
        { value: 1, label: 'Low', component: <Tag title="Low" $color={styles.colors.blue600} /> },
        { value: 2, label: 'Medium', component: <Tag title="Medium" $color={styles.colors.green500} /> },
        { value: 3, label: 'High', component: <Tag title="High" $color={styles.colors.orange600} /> },
        { value: 4, label: 'Urgent', component: <Tag title="Urgent" $color={styles.colors.red600} /> }
    ]

    const typeOptions = [
        { value: 'No Type', label: 'No Type' },
        { value: 'General', label: 'General' },
        { value: 'Deadline', label: 'Deadline' },
        { value: 'In-person meeting', label: 'In-person meeting' },
        { value: 'Online meeting', label: 'Online meeting' },
        { value: 'Email', label: 'Email' },
        { value: 'Call', label: 'Call' },
        { value: 'Book a meeting', label: 'Book a meeting' },
        { value: 'LinkedIn message', label: 'LinkedIn message' },
        { value: 'LinkedIn connection add', label: 'LinkedIn connection add' }
    ]

    const { updateTaskHandler, createTaskHandler, bulkUpdateTasksHandler } = useTasksHandlers()
    const { closeSidebar } = useAppHandlers()
    const { bulkAddRemindersHandler } = useRemindersHandlers()
    const { getWorkspaceUsersPublicHandler } = useWorkspaceHandlers()

    const [title, setTitle] = useState<string>(editTask ? task.title : '')
    const [assignee, setAssignee] = useState<any | undefined>(editTask && task.assigned.length ? {
            label: <Text $label>{capitalLetters(task.assigned[0], true)}</Text>,
            value: task.assigned[0]
        } : undefined)
    const [linkedConnection, setLinkedConnection] = useState<IConnection | undefined>(editTask && task.associations.connections.length ? (
        {
            ...task.associations.connections[0].profile,
            _id: task.associations.connections[0]._id
        }
    ) : undefined)
    const [dueDate, setDueDate] = useState<any>(editTask ? (
        {
            end: task.end,
            repeating: task.repeating
        }
    ) : undefined)
    const [reminder, setReminder] = useState<any>(editTask && task?.reminders?.length ? task?.reminders?.[0].end : undefined)
    const [priority, setPriority] = useState<any>(editTask && task?.priority !== 0 && priorityOptions.find((option: any) => option.value === task?.priority))
    const [type, setType] = useState<any>(editTask && typeOptions.find((option: any) => option.value === task?.type && task?.type !== 'No Type'))
    const [description, setDescription] = useState<string>(editTask ? task.value : '')
    const [ workspaceUsers, setWorkspaceUsers ] = useState<any>([])

    const getWorkspaceUsersPublic = async () => {
        const result = await getWorkspaceUsersPublicHandler()
        setWorkspaceUsers(result.response ?? [])
    }
    
    const cancelHandler = () => {
        closeSidebar(SidebarTypes.TASK_EDIT_CREATE)
        onCloseHandler && onCloseHandler();
    }

    const saveHandler = () => {
        closeSidebar(SidebarTypes.TASK_EDIT_CREATE)

        let body = {
            title: title,
            assigned: assignee ? [assignee.value] : [],
            associations: { connections: linkedConnection ? [linkedConnection] : (connection ? [connection] : []) },
            end: !!dueDate?.end ? dueDate?.end : 0,
            repeating: { unit: dueDate?.repeating?.unit ?? 'Never', value: 1, active: true },
            priority: priority ? priority.value : 0,
            type: type ? type.label : 'No Type',
            reminder: reminder ? reminder : false,
            value: description ?? ''
        }

        if(isDueDate) {
            bulkUpdateTasksHandler({
                end: !!dueDate?.end ? dueDate?.end : 0,
                repeating: { unit: dueDate?.repeating?.unit ?? 'Never', value: 1, active: true },
            })
        } else if(isPriorityLevel) {
            bulkUpdateTasksHandler({priority: priority?.value})
        } else if(isReminder) {
            bulkAddRemindersHandler({reminder: reminder ? reminder : false})
        } else if(isShortForm) {
            onCreateHandler && onCreateHandler(task?._id, body)
        } else if(editTask) {
            updateTaskHandler(task._id, body)
        }  else {
            createTaskHandler(body)
        }
    }

    useEffect(() => {
        getWorkspaceUsersPublic()
    }, [])

    return (
        <Column gap="10px">
            {(isAll || isShortForm) && <section>
                <Text $label>{`Task Name ${title.length === 0 ? '(Required)' : ''}`}</Text>
                <Input placeholder="Enter task name" prefilledValue={title} onChangeHandler={(e: any) => setTitle(e)} />
            </section>}
            {isAll && <section>
                <Text $label>Assignee</Text>
                <Select
                    placeholder="Enter assignee name"
                    isSearchable={false}
                    options={workspaceUsers.map((workspaceUser: any) => ({
                        value: workspaceUser,
                        label: 
                        <Row gap="6px" alignItems>
                            <ProfilePhoto tooltipMessage="" capitalLetters={capitalLetters(workspaceUser)} />
                            <Column>
                                {<Text $label>{capitalLetters(workspaceUser, true)}</Text>}
                                <Text $lighter>{workspaceUser.email}</Text>
                            </Column>
                        </Row>
                    }))}
                    value={assignee}
                    onChange={(e: any) => setAssignee({ label: <Text $label>{capitalLetters(e.value, true)}</Text>, value: e.value} )}
                />
            </section>}
            {isAll && <section>
                <Text $label>Linked connection</Text>
                <ConnectionAutocompleteSelect
                    prefilledValue={linkedConnection}
                    onChangeHandler={(e: any) => setLinkedConnection(e)}
                />
            </section>}
            {(isAll || isDueDate || isShortForm) && <section>
                <Text $label>Due date</Text>
                <DueDate
                    end={dueDate?.end}
                    repeating={dueDate?.repeating}
                    onChangeHandler={(e: any) => setDueDate(e)}
                />
            </section>}
            {(isAll || isReminder || isShortForm) && <section>
                <Text $label>Reminder</Text>
                <Reminder
                    end={reminder}
                    onChangeHandler={(e: any) => setReminder(e)}
                />
            </section>}
            {(isAll || isPriorityLevel) && <section>
                <Text $label>Priority level</Text>
                <Select
                    $components
                    placeholder="No priority level"
                    isSearchable={false}
                    options={priorityOptions}
                    value={priority}
                    onChange={(e: any) => setPriority(e)}
                />
            </section>}
            {isAll && <section>
                <Text $label>Type</Text>
                <Select
                    placeholder="No type"
                    isSearchable={false}
                    options={typeOptions}
                    value={type}
                    onChange={(e: any) => setType(e)}
                />
            </section>}
            {isAll && <section>
                <Text $label>Task description</Text>
                <CustomTextArea
                    maxLength={400}
                    placeholder='Enter description'
                    value={description ?? ''}
                    onChange={(e: any) => setDescription(e.target.value)}
                />
            </section>}
            <ButtonWrapper>
                <Button $type="cancel" onClickHandler={cancelHandler}>Cancel</Button>
                <Button $type="blue" disabled={editTask && title.length === 0} onClickHandler={saveHandler}>Save</Button>
            </ButtonWrapper>
        </Column>
    )
}

const Label = styled(Text)`
    color: ${styles.colors.black400};
    text-transform: uppercase;
    font-size: 11px;
`
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 10px;
`
export default CreateEditTaskForm