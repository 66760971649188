import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import Table from '../../../../components/Table'
import Checkbox from '../../../../components/Checkbox'
import { initialConnectionsFilter, useConnectionsStore } from '../../../../stores/connections/useConnectionsStore'
import { useConnectionsHandlers } from '../../../../services/actions/connections/useConnectionsHandlers'
import { IConnection, ITag } from '../../../../interfaces/models/connection'
import ProfilePhoto from '../../../../components/ProfilePhoto'
import SvgFragment from '../../../../fragments/SvgFragment'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import { calculateColumnWidth, capitalLetters, formatNumber, userVersionToString } from '../../../../utils'
import Button from '../../../../components/Button'
import SelectAllCheckbox from '../../../../components/SelectAllCheckbox'
import Text from '../../../../components/Text'
import Column from '../../../../components/Column'
import ConnectionTags from '../../../../fragments/ConnectionTags'
import moment from 'moment'
import { ICreateNote, useNotesHandlers } from '../../../../services/actions/notes/useNotesHandlers'
import { useAppStore } from '../../../../stores/application/useAppStore'
import ConnectionActionDropdown from '../../../../fragments/dropdowns/ConnectionActionDropdown'
import { useLocation, useNavigate } from 'react-router-dom'
import EmptyPage from '../../../../components/EmptyPage'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import TooltipWrapper from '../../../../components/TooltipWrapper'
import useMediaQuery from '../../../../components/useMediaQuery'
import styled from 'styled-components'
import { styles } from '../../../../styles/themes/style'
import Row from '../../../../components/Row'

interface IConnectionTableProps {
    selectedTags?: ITag[],
    selectedConnections: IConnection[]
    selectedConnectionsChange: (connections: IConnection[]) => void;
    selectedAllConnectionsChange: (value: boolean) => void;
}

const ConnectionTable: FunctionComponent<IConnectionTableProps> = ({selectedConnections, selectedConnectionsChange, selectedAllConnectionsChange}) => {
    const tableRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const location = useLocation()
    const {
        tag,
        note,
        noTags,
        industry,
        searchByLocation: {
            searchByLocation = '',
            locationSearchValue = ''
        } = {},
        searchByJobTitle: {
            searchByJobTitle = '',
            jobTitleSearchValue = ''
        } = {}
    } = location.state || {}

    const isMedium = useMediaQuery("(max-width: 1240px)");
    const isSmall = useMediaQuery("(max-width: 768px)");

    const { store: { connections, connectionsFilter, connectionsParameters } } = useConnectionsStore()
    const {  setConnectionsFilterHandler } = useConnectionsHandlers()
    const { createUpdateNoteHandler } = useNotesHandlers()
    const { openSidebar, closeSidebar, openExtension, showInfoToast } = useAppHandlers()
    const { store: { requestLoading }} = useAppStore()
    const { store: { workspace: { showAutoTags }, user }} = useAuthStore()
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false)
    const [ connectionsLoading, setConnectionsLoading ] = useState<boolean>(true)
    const [lastFetchTimestamp, setLastFetchTimestamp] = useState<number>(0)

    const { page, pageSize, total } = connectionsParameters

    const noResultTitle = "No Connections To Show";
    const noResultDescription = "Install Chrome Extension, Sync Connections, or wait for the Sync to complete. You can also import Connections via CSV import.";

    useEffect(() => {
        // Filtering passed as location state
        if(note || tag || noTags || industry || searchByLocation || searchByJobTitle) setConnectionsFilterHandler({
            ...initialConnectionsFilter,
            ...note ? { note: { value: note, label: note } } : {},
            ...tag ? { selectedTags: [tag] } : {},
            ...noTags ? { noTags: noTags } : {},
            ...industry ? { industry: { value: industry, label: industry }} : {},
            ...searchByLocation ? { searchByLocation: searchByLocation, searchValue: locationSearchValue } : {},
            ...searchByJobTitle ? { searchByJobTitle: searchByJobTitle, searchValue: jobTitleSearchValue } : {}
        })
    }, [])

    //Clear router location state
    // useEffect(() => {
    //     if (location.state) navigate(location.pathname, { state: null })
    // }, [navigate, location])

    //add another for is fetching depending on the slug

    const getSortParams = (header: string, sort: string): string => {
        switch (header) {
            case 'Name': return sort
            case 'Company': return `company.${sort}`
            case 'Location': return `location.${sort}`
            case 'Date Connected': return sort === 'za' ? 'oldest' : ''
            case 'Followers': return `followerCount.${sort}`
            case 'Contact Info': return `email.${sort}`
            case 'Last Messaged': return `lastMessaged.${sort === 'za' ? 'desc' : 'asc'}`

            default: return ''
        }
    }

    const adjustedColumnsHandler = useCallback(() => {
        const localStorageColumnsSetup = localStorage.getItem('columns-setup')
        let columnsSetup = localStorageColumnsSetup ? JSON.parse(localStorageColumnsSetup) : {}

        const mappedColumnsWithShow = columns.map((column: any) => {
            if(column.sortHandler)
                column.sortHandler = (e: any) => sortClickHandler(e)
            if (columnsSetup && columnsSetup?.hasOwnProperty(column.key[0])) {
                return {
                    ...column,
                    show: columnsSetup[column.key[0]]
                }
            }
            return {
                ...column
            }
        })

        const offsetWidth = tableRef.current?.offsetWidth
        const adjustedColumns = calculateColumnWidth(offsetWidth, mappedColumnsWithShow, isSmall, isMedium)

        return adjustedColumns
    }, [connectionsFilter, isSmall, isMedium]);

    const sortClickHandler = (props: any) => {
        const adjustedColumns = adjustedColumnsHandler()

        const selectedColumnIndex = adjustedColumns.findIndex((column: any) => column.key === props.key)
        if (selectedColumnIndex !== -1) {

            props.sort = (props.sort === '' || props.sort === 'za') ? 'az' : 'za'
            adjustedColumns[selectedColumnIndex] = props

            setColumns(adjustedColumns)
        
            setConnectionsFilterHandler({...connectionsFilter, sort: getSortParams(props.header, props.sort) })
        }
    }
    useEffect(() => {
        if('getConnectionsPaginate' in requestLoading) setConnectionsLoading(requestLoading?.['getConnectionsPaginate'])
    }, [requestLoading]);


    const selectAllCheckboxHandler = () => {
        const selectedIds = !isAllSelected ? [...connections] : []
        setIsAllSelected(!isAllSelected)
        selectedConnectionsChange(selectedIds)
    }

    useEffect(() => {
        selectedAllConnectionsChange(isAllSelected)
    }, [isAllSelected]);

    const selectCheckboxHandler = (e: React.MouseEvent<HTMLElement>, connection: IConnection) => {
        e.stopPropagation()

        if(isAllSelected) return showInfoToast({ message: 'You can\'t use this action while all connections are selected.'})

        const index = selectedConnections.findIndex(selectedConnection => selectedConnection._id === connection._id)
        let updatedIds = []

        if (index !== -1) {
            updatedIds = selectedConnections.filter((item: any) => String(item._id) !== String(connection._id));
            selectedConnectionsChange(updatedIds);
            setIsAllSelected(false)
        } else {
            updatedIds = [...selectedConnections, connection];
            selectedConnectionsChange(updatedIds);
            setIsAllSelected(false)
        }
    }

    const noteClickHandler = (e: React.MouseEvent<HTMLElement>, connection: IConnection) => {
        e.stopPropagation();
        openSidebar(SidebarTypes.NOTE_EDIT_CREATE, { connectionId: connection._id, existingNotes: connection.notes,
            onCloseHandler: () => closeSidebar(SidebarTypes.NOTE_EDIT_CREATE), 
            onCreateHandler: onreateOrUpdateNoteHandler
        })
    }

    const onreateOrUpdateNoteHandler = async (
        noteId: string | null,
        note: ICreateNote,
        updateNotes?: (notes: any) => void
    ) => {
        const result = await createUpdateNoteHandler(noteId, note);
        if (result && updateNotes) {
            updateNotes(result.notes);
        }
    };

    const messageClickHandler = (e: React.MouseEvent<HTMLElement>, connection: IConnection) => {
        e.stopPropagation();
        openExtension({ query: `redirectTo=inbox&messagedConnection=${connection._id}`, newTab: false })
    }

    const selectOnThisPageHadler = () => {
        const newConnections = connections.filter((connection: IConnection) => !selectedConnections.some(selectedConnection => connection._id === selectedConnection._id))
        selectedConnectionsChange([...selectedConnections, ...newConnections])
    }

    useEffect(() => {
        clearHandler();
    }, [connections])

    const clearHandler = () => {
        setIsAllSelected(false)   
        selectedAllConnectionsChange(false)
        selectedConnectionsChange([])
    }


    const [columns, setColumns] = useState([
        { header: 'MSG', key: ['messageColumn'], width: '34px', show: true, showSmall: true }, 
        { header: 'NOTE', key: ['note'], width: '34px', show: true, showSmall: true  },
        { header: '', key: ['profilePictureMapped'], width: '45px', show: true },
        { header: 'Name', key: ['fullName'], width: '100px', sort: '', sortHandler: (e: any) => sortClickHandler(e), show: true, showSmall: true },
        { header: 'Headline & Job title', key: ['headlineAndJobTitle'], width: '200px', show: true, showSmall: true },
        { header: 'Company', key: ['company'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: true  },
        { header: 'Location', key: ['location'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: true  },
        { header: 'Date Connected', key: ['dateConnectedColumn'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: false  },
        { header: 'Languages', key: ['languagesColumn'], show: false },
        { header: 'Date of Birth', key: ['dateOfBirth'], show: false },
        { header: 'Followers', key: ['followerCountColumn'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: false },
        { header: 'Contact Info', key: ['emailColumn'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: true },
        { header: 'Last Messaged', key: ['lastMessagedColumn'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: true },
        { header: 'Tags', key: ['tagsColumn'], show: true, showMedium: true },
        { header: 'Shared Connections', key: ['mutualConnections'], show: false },
        { header: 'Actions', key: ['actions'], show: true, showSmall: true },
    ])

    useEffect(() => {
        const adjustedColumns = adjustedColumnsHandler()
        
        setColumns(adjustedColumns)
    }, [connections, isMedium, isSmall])

    useEffect(() => {
        const adjustedColumns = adjustedColumnsHandler()
        
        setColumns(adjustedColumns)
    }, [connectionsParameters])

    //todo: add no data placeholder and preloaders
    return <Table
        ref={tableRef}
        isConnectionTable
        columns={[
            {
                header:
                    <SelectAllCheckbox
                        isCheckboxChecked={selectedConnections.length > 0 || (isAllSelected ?? false)}
                        pageSize={pageSize}
                        total={total}
                        paginated={connections.length}
                        isAllSelected={isAllSelected ?? false}
                        selected={selectedConnections.length}
                        label={'connections'}
                        selectAllHandler={selectAllCheckboxHandler}
                        selectOnThisPageHadler={selectOnThisPageHadler}
                        clearHandler={clearHandler}
                    />,
                    key: ['checkbox'],
                    width: '25px',
                    showSmall: true
                },
                ...columns
        ]}
        pageLoading={!connections?.length && connectionsLoading ? pageSize : undefined}
        noDataPage={<EmptyPage svgType='templateEmpty' title={noResultTitle} label={noResultDescription} />}
        data={connections.map((connection: IConnection) => {
            const version = connection?.users.filter((e: any) => e?._id === user?._id)
            const lastMessagedAt = connection?.lastMessagedAtArray?.filter((lastMessagedAt: any) => user && lastMessagedAt._id === user?._id)?.[0];
            return (
                {
                    object: { ...connection },
                    onRowClick: (sentConnection: IConnection) => openSidebar(SidebarTypes.CONNECTION_ACTIONS, { connection: sentConnection }),
                    checkbox: (
                        <Checkbox
                            checked={selectedConnections.some((selectedConnection: IConnection) => selectedConnection._id === connection._id || isAllSelected)}
                            checkboxId={connection._id}
                            onClickHandler={(e: React.MouseEvent<HTMLElement>) => selectCheckboxHandler(e, connection)}
                        />
                    ),
                    fullName: (
                        <Column>
                            <Text $ellipsis='0'>{connection.firstName}</Text>
                            <Text $ellipsis='0'>{connection.lastName}</Text>
                        </Column>
                    ),
                    profilePictureMapped: (
                        <ProfilePhoto source={connection.profilePicture} />
                    ),
                    messageColumn: (
                        <Button transparent disabled={version?.[0]?.version !== 'in-1st'} $smallButton onClickHandler={(e: React.MouseEvent<HTMLElement>) => messageClickHandler(e, connection)} $SVGtype={'message'} />
                    ),
                    note: (
                        <Button $smallButton onClickHandler={(e: React.MouseEvent<HTMLElement>) => noteClickHandler(e, connection)} $SVGtype={connection.notes.length !== 0 ? 'noteFull' : 'note'}></Button>
                    ),
                    headlineAndJobTitle: (
                        <Column>
                            <Text $ellipsis='1' showTooltip>{connection.jobTitle}</Text>
                            <Text $ellipsis='2' $faded showTooltip>{connection.headline}</Text>
                        </Column>
                    ),
                    tagsColumn: (
                        <ConnectionTags tagColumn={columns.find(column => column.header === "Tags")} tags={showAutoTags ? connection?.tags : connection?.tags?.filter(tag => !tag.isForbidden)} />
                    ),
                    dateOfBirth: (
                        <Text>{connection?.birthDate ? moment(connection.birthDate.month + " " + connection.birthDate.day, 'MM DD').format('MMM D') : ''}</Text>
                    ),
                    languagesColumn: (
                        <Column>{connection?.languages?.map((language: any) => { return <Text key={language}>{language}</Text> })}</Column>
                    ),
                    emailColumn: (
                        <Column>
                            <Text $ellipsis='0' showTooltip>{connection.email}</Text>
                            <Text $faded>{connection.phoneNumber}</Text>
                        </Column>
                    ),
                    mutualConnections: (
                        <StyledRow>
                            {connection?.users?.map((user: any) => { 
                                return <ProfilePhoto key={user?._id} className={`connection-version-${user?.version}`} tooltipMessage={capitalLetters(user, true) + ' ' + userVersionToString(user.version) + ' connection'} capitalLetters={capitalLetters(user)} />})}
                        </StyledRow>
                    ),
                    lastMessagedColumn: (
                        <StyledMessaged 
                        tooltipMessage={lastMessagedAt ? `${lastMessagedAt?.sent ? 'Sent at ' : 'Received at '} ${moment(lastMessagedAt?.lastMessagedAt).format('MMM D, YYYY [at] HH:mm')}` : ''}
                        $sent={lastMessagedAt?.sent}>
                            {lastMessagedAt ? moment(lastMessagedAt?.lastMessagedAt).fromNow() : ''}
                        </StyledMessaged>
                    ),
                    followerCountColumn:(
                        <Text>{connection?.followerCount ? formatNumber(connection?.followerCount) : ''}</Text>
                    ),
                    dateConnectedColumn:(
                        <Text>{connection?.connectedAt ? moment(connection.connectedAt).format('MMM D, YYYY [at] HH:mm') : ''}</Text>
                    ),
                    actions: 
                        (<div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>
                            <ConnectionActionDropdown connection={connection}/>
                        </div>),
                    ...connection
                }
            )
        })}
    />
}

const StyledMessaged = styled(Text)<{$sent: boolean}>`
    color: ${({ $sent }) => $sent ? styles.colors.black300 : styles.colors.green600 }
`
const StyledRow = styled(Row)`
    & > *:not(:first-child) {
        margin-left: -2px;
    }
`
export default ConnectionTable
