import { ActionType, TAuthAction, TAuthState } from "../../interfaces/store/authStore";
import { makeStore } from "../makeStore";


const initialState: TAuthState = {
    authData: {
        accessToken: undefined,
        refreshToken: undefined
    },
    user: undefined,
    workspace: undefined,
    context: "individual",
    lifetime: false,
    trial: true,
    validSubscription: false,
    workspaceOwner: false,
    subscription: undefined,
    userWorkspaces: [],
    disablePremiumFeatures: true,
    extensionInboxTaskId: undefined,
    disconnectedConnectionsCount: 0
}

const reducer = (state: TAuthState, action: TAuthAction): TAuthState => {
    switch (action.type) {
        case ActionType.SET_AUTH_DATA: return { ...state, authData: action.payload }
        case ActionType.SET_USER: return { ...state, user: action.payload }
        case ActionType.SET_WORKSPACE: return { ...state, workspace: action.payload }
        case ActionType.SET_CONTEXT: return { ...state, context: action.payload }
        case ActionType.SET_LIFETIME: return { ...state, lifetime: action.payload }
        case ActionType.SET_TRIAL: return { ...state, trial: action.payload }
        case ActionType.SET_VALID_SUBSCRIPTION: return { ...state, validSubscription: action.payload }
        case ActionType.SET_WORKSPACE_OWNER: return { ...state, workspaceOwner: action.payload }
        case ActionType.SET_SUBSCRIPTION : return { ...state, subscription: action.payload }
        case ActionType.SET_USER_WORKSPACES: return { ...state, userWorkspaces: action.payload }
        case ActionType.SET_DISABLE_PREMIUM_FEATURES: return { ...state, disablePremiumFeatures: action.payload }
        case ActionType.SET_EXTENSION_INBOX_TASK_ID: return { ...state, extensionInboxTaskId: action.payload }
        case ActionType.SET_DISCONNECTED_CONNECTIONS_COUNT: return { ...state, disconnectedConnectionsCount: action.payload }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: AuthStoreProvider, useStoreContext: useAuthStore } = makeStore(reducer, initialState)

export { AuthStoreProvider, useAuthStore }